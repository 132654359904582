//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  text-align: center;
  &:before {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[class*='icon-circle'] {
  border-radius: 50%;
  overflow: hidden;
}

// Alternate icons
// --------------------------------------------------

.icon-default {
  color: $body-color;
}

.icon-white {
  color: $white;
}

.icon-primary {
  color: $primary;
}

.icon-jungle-green {
  color: $jungle-green;
}

.icon-gray-dark {
  color: $gray-dark;
}

a {
  .icon {
    color: inherit;
    transition: .33s all ease;
  }

  &.icon-default {
    @include link($body-color, $primary);
  }

  &.icon-primary {
    @include link($primary, $white);
  }

  &.icon-jungle-green {
    @include link($jungle-green, $gray-dark);
  }

  .icon-jungle-green-contrast {
    @include link($jungle-green, $white);

    &.icon-circle {
      &,
      &:active,
      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: $primary;
      }
    }
  }
}

// Button Sizes
// --------------------------------------------------

.icon-xs {
  @include icon-sizing(21px);

  &.icon-circle {
    width: 50px;
    height: 50px;
    line-height: 52px;
  }
}

.icon-sm {
  @include icon-sizing(42px);
  @include media-breakpoint-up(md) {
    @include icon-sizing(48px);
  }
}

.icon-md {
  @include icon-sizing(42px);
 @include media-breakpoint-up(md) {
   @include icon-sizing(52px);
 }
}

.icon-lg {
  @include icon-sizing(60px);
}

.icon-xl {
  @include icon-sizing(76px);
  @include media-breakpoint-up(md) {
    @include icon-sizing(100px);
  }
}

