//
// Custom typography
// --------------------------------------------------

// Page header
// -------------------------

.page-header {
}

// Fonts
// -------------------------

.font-default {
  font-family: $font-family-base;
}

.font-size-default {
  font-size: $font-size-body;
  line-height: $line-height-base;
}

// Basic Typography
// -------------------------

#{headings()} {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
  text-transform: uppercase;

  > span {
    font-size: inherit;
  }

  a {
    display: inline;
    font: inherit;
    letter-spacing: inherit;
    transition: .33s all ease;
    &, &:active, &:focus {
      color: $primary;
    }

    &:hover {
      color: $headings-color;
    }
  }
}

h1,
.h1 {
  //{0 - 768px} <= 40px
  //{768px - 992px} <= 62px
  font-size: 26px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }

  @include media-breakpoint-up(xl) {
    line-height: $h1-line-height;
    font-size: $h1-font-size;
  }
}

h2,
.h2 {
  //{0 - 768px} <= 36px
  //{768px - 992px} <= 52px
  font-size: 28px;
  line-height: 1.4;

  @include media-breakpoint-up(md) {
    font-size: 38px;
  }

  @include media-breakpoint-up(lg) {
    line-height: $h2-line-height;
    font-size: $h2-font-size;
  }
}

h3,
.h3 {
  //{0 - 768px} <= 32px
  //{768px - 992px} <= 44px
  font-size: 26px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 38px;
  }

  @include media-breakpoint-up(xl) {
    line-height: $h3-line-height;
    font-size: $h3-font-size;
  }
}

h4,
.h4 {
  //{0 - 768px} <= 26px
  //{768px - 992px} <= 36px
  font-size: 24px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }

  @include media-breakpoint-up(xl) {
    line-height: $h4-line-height;
    font-size: $h4-font-size;
  }
}

h5,
.h5 {
  //{0 - 768px} <= 22px
  //{768px - 992px} <= 28px
  font-size: 18px;
  line-height: 1.2;

  @include media-breakpoint-up(md) {
    font-size: 20px;
  }

  @include media-breakpoint-up(xl) {
    line-height: $h5-line-height;
    font-size: $h5-font-size;
  }
}

h6,
.h6 {
  //{0 - 768px} <= 18px
  //{768px - 992px} <= 22px
  font-size: 16px;
  line-height: 1.2;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }

  @include media-breakpoint-up(md) {
    line-height: $h6-line-height;
    font-size: $h6-font-size;
  }
}

* + .big {
  margin-top: 18px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }
}

.big {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0;
}

@include media-breakpoint-up(md) {
  .big {
    font-size: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .big {
    font-size: $font-size-lg;
    line-height: floor(($font-size-lg * $line-height-large));
  }
}

small,
.small {
  font-size: $font-size-sm;
  line-height: floor(($font-size-sm * $line-height-small));
}

code {
  padding: 5px 7px;
  font-size: 75%;
  color: $brand-danger;
  background-color: $gray-lighter;
  border-radius: 2px;
}

mark, .mark {
  background-color: $primary;
  padding: .1em;
}

.text-extra-big {
  font-size: 120px;
  font-weight: 700;
  line-height: 1;
  color: $white;

  @include media-breakpoint-up(md) {
    font-size: 180px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 218px;
  }
}

// Misc
// -------------------------

// Addresses
address {
  margin-top: 0;
  margin-bottom: 0;
}

// Backgrounds
// -------------------------

// Contextual backgrounds
//
%context-dark {
  @extend %context-dark;
  &, #{headings()} {
    color: $white;
  }

  a {
    &, &:active, &:focus {
      color: $primary;
    }

    &:hover {
      color: $white;
    }
  }
}

// Colors
.bg-black {
  @extend %context-dark;
  @include bg-variant-custom(#{$black});
}

.bg-gray-darker {
  @extend %context-dark;
  @include bg-variant-custom(#{$gray-darker});
}

.bg-gray-dark {
  @extend %context-dark;
  @include bg-variant-custom(#{$gray-dark});
}

.bg-gray {
  @include bg-variant-custom(#{$gray});
}

.bg-accent {
  @include bg-variant-custom(#{$primary});
  color: $white;
}

.bg-gray-lighter {
  @include bg-variant-custom(#{$gray-lighter});
}

// Bg image
.bg-image {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}

.bg-image-1 {
  @include media-breakpoint-down(sm) {
    background: $white !important;
  }

  @include media-breakpoint-between(md, xl) {
    position: relative;
    z-index: 0;

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba($white, .3);
    }
  }
}

// Colors
// -------------------------

// Contextual colors
//

.page {
  @include text-emphasis-variant('.text-primary', $primary);
  @include text-emphasis-variant('.text-dark', $gray-dark);
  @include text-emphasis-variant('.text-gray', $gray);
  @include text-emphasis-variant('.text-gray-darker', $gray-darker);
  @include text-emphasis-variant('.text-white', $white);
}
