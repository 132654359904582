//
// Post utilities
// --------------------------------------------------

.post {
  text-align: center;
  .post-meta {
    font-size: 14px;
    vertical-align: baseline;

    > * {
      margin-right: .25em;
    }
  }

  * + .post-meta {
    margin-top: 33px;
  }

  * + .post-body {
    margin-top: 13px;
  }
}

@include media-breakpoint-up(md) {
  .post {
  }
}

@include media-breakpoint-up(lg) {
  .post {
  }
}

@include media-breakpoint-up(xl) {
  .post {
  }
}

@include media-breakpoint-up(xxl) {
  .post {
  }
}