//
// Wrappers
// --------------------------------------------------
.object-wrap {
  position: relative;
  overflow: hidden;
}

// Positioning
.object-wrap-right {
  > .object-wrap-body {
    right: 0;
  }
}

.object-wrap-left {
  > .object-wrap-body {
    left: 0;
  }
}

@include media-breakpoint-up(md) {
  .object-wrap-sm-right {
    > .object-wrap-body {
      right: 0;
    }
  }

  .object-wrap-sm-left {
    > .object-wrap-body {
      left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .object-wrap-body {
    position: relative;
    overflow: hidden;
    min-height: 300px;
    width: 100%;

    > img {
      position: absolute;
      min-width: 100%;
      max-width: none;
      height: auto;
      max-height: none;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -20%);
    }
  }
}

@include media-breakpoint-up(md) {
  .object-wrap-body {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    min-width: 1px;
    max-width: none;
    height: 100%;
    min-height: 100%;
    max-height: none;
    margin: 0;
    background: inherit;
    z-index: 0;

    > img {
      position: relative;
      height: auto;
      min-height: 100.5%;
      width: auto;
      min-width: 102%;
      max-width: none;
      left: 50%;
      transform: translateX(-50%);
    }

    & + * {
      margin-top: 0;
    }
  }
}

// sizes
@include media-breakpoint-up(md) {
  .sm-width-c6 {
    @include make-grid-parameter(width, 0, map-get($container-max-widths, md), 6);
  }
}

.md-width-c7d20 {
  @include media-breakpoint-up(lg) {
    @include make-grid-parameter(width, 20px, map-get($container-max-widths, lg), 7);
  }

  @include media-breakpoint-up(xl) {
    @include make-grid-parameter(width, 20px, map-get($container-max-widths, xl), 7);
  }
}

.md-width-c5dm20 {
  @include media-breakpoint-up(lg) {
    @include make-grid-parameter(width, -20px, map-get($container-max-widths, lg), 5);
  }

  @include media-breakpoint-up(xl) {
    @include make-grid-parameter(width, -20px, map-get($container-max-widths, xl), 5);
  }
}

// bg-wrap
.bg-wrap {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 120vw;
    left: 50%;
    transform: translateX(-50%);
    background: inherit;
    z-index: -1;
  }
}

.bg-wrap-sm-left {
  z-index: 1;
  @include media-breakpoint-up(lg) {
    &:before {
      width: 100vw;
      right: 0;
      transform: none;
    }
  }
}

.bg-wrap-sm-right {
  z-index: 1;
  @include media-breakpoint-up(lg) {
    &:before {
      width: 100vw;
      left: 0;
      transform: none;
    }
  }
}

// Content wrap
[class*='content-wrap'] {
  padding: 40px 20px;
}

@include media-breakpoint-up(md) {
  .content-wrap-1 {
    padding: 43px 81px 82px 79px;
  }
}

// wrap equal
.wrap-equal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > * {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

// Image wrap
.image-wrap-variant-1 {
  img {
    width: 385px;
    margin-left: -40px;
    @include media-breakpoint-up(sm) {
      margin-left: -60px;
    }
    @include media-breakpoint-up(md) {
      width: 130%;
      margin: 0 0 0 -65px;
    }
    @include media-breakpoint-up(lg) {
      width: 140%;
      margin: 0 0 0 -130px;
    }
  }
}

.image-wrap-variant-2 {
  text-align: center;

  @include media-breakpoint-down(sm) {
    img {
      max-width: 370px;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 375px;
      margin: 70px 0px 0px -47px;
    }
  }

  @include media-breakpoint-up(xl) {
    img {
      width: 479px;
      margin: -8px 0px 0px -52px;
    }
  }

  @include media-breakpoint-up(xxl) {
    img {
      width: auto;
      margin: -8px 0px 0px -44px;
    }
  }
}

.image-wrap-variant-3 {
  @include media-breakpoint-up(md) {
    img {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include media-breakpoint-up(lg) {
    img {
      width: 950px;
      margin: 55px 0 0 -341px;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      width: auto;
      margin: -59px 0 0 -438px;
    }
  }
}

.image-wrap-variant-4 {
  text-align: center;
  @include media-breakpoint-up(md) {
    img {
      width: 80%;
      margin-right: -40px;
    }
  }
  @include media-breakpoint-up(lg) {
    img {
      width: 486px;
      margin: 74px 0 0 0;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      width: auto;
      margin: 20px 0 0 0;
    }
  }
}

.image-wrap-variant-5 {
  @include media-breakpoint-up(md) {
    text-align: center;
    img {
      width: 70%;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 469px;
      margin: 85px 0 0 -20px;
    }
  }
  @include media-breakpoint-up(xl) {
    img {
      width: auto;
      margin: 12px 0 0 -52px;
    }
  }
}

.flex-wrap {
  .flex-wrap-footer {
    margin-top: 30px;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .flex-wrap-footer {
      margin-bottom: 22px;
    }
  }
}