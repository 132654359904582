//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 10px, 15px, 60px);

@include indent-responsive(inset, left, padding-left, $grid-breakpoints, $insets);
@include indent-responsive(inset, right, padding-right, $grid-breakpoints, $insets);


// Elements
// -------------------------
h3 + .big { margin-top: 12px; }


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------
.offset-custom {
  &-1 { margin-top: 50px; }
  &-2 { margin-top: 40px; }
}

* + .offset-top-40 { margin-top: 40px; }

// Medium ≥768px
@include media-breakpoint-up(md) {
  .offset-custom {
    &-1 { margin-top: 75px; }
  }

  * + .offset-md-top-65 { margin-top: 65px; }
}


// Range spacing
.row-25 { @include grid-offset(25px); }
.row-30 { @include grid-offset(30px); }
.row-40 { @include grid-offset(40px); }
.row-45 { @include grid-offset(45px); }
.row-50 { @include grid-offset(50px); }
.row-60 { @include grid-offset(60px); }

// Medium ≥768px
@include media-breakpoint-up(md) {
  .row-md-70 { @include grid-offset(70px); }
}

@include media-breakpoint-up(lg) {
  .row-lg-50 { @include grid-offset(50px); }
}
