//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

$insets: (0, 30px, 40px, 55px, 60px, 66px, 87px, 90px, 95px, 100px, 115px, 140px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $grid-breakpoints, $insets);
@include indent-responsive(section, top, padding-top, $grid-breakpoints, $insets);
@include indent-responsive(section, bottom, padding-bottom, $grid-breakpoints, $insets);