/*
*
* Circle Progress Bars
*/

.progress-bar-circle {
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;

	canvas {
		max-width: 100%;
		vertical-align: middle;
	}

	span {
		position: absolute;
		top: 50%;
		left: 51%;
		font-size: 42px;
		line-height: 42px;
		transform: translate(-50%, -50%);
		color: $gray-darker;

		&::after {
			content: "%";
		}
	}
}

.progress-bar-circle-title {
	/*font-size: 14px;
	letter-spacing: .05em;*/
	text-transform: uppercase;
}

* + .progress-bar-circle-title {
	margin-top: 15px;
}

@include media-breakpoint-up(xl) {
	.progress-bar-circle {

		canvas {

		}

		span {
			font-size: 60px;
			line-height: 60px;
		}
	}

	* + .progress-bar-circle-title {
		margin-top: 32px;
	}
}


%context-dark {
	.progress-bar-circle {
		span {
			color: $white;
		}
	}
}