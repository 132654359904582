//
// Links
// --------------------------------------------------
.link {
  display: inline-block;
}

.link-inline {
  font: inherit;
  line-height: inherit;
  text-decoration: underline;
}

.link-underline {
  &, &:active, &:focus {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: none;
  }
}

.page {
  .link-white {
    @include link($white, $primary);
  }

  .link-primary {
    @include link($primary, $gray-dark);
  }
}