//
// Comment utilities
// --------------------------------------------------
.comment {

  figure {
    img {
      width: auto;
      height: auto;
    }
  }

  .comment-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
  }

  .comment-meta {
    $value: 8px;

    position: relative;
    display: block;
    width: 100%;
    margin: 15px 0 0 0;
    transform: translateY(-$value);
    vertical-align: middle;
    color: $gray;

    > * {
      display: inline-block;
      margin-top: $value;
      font-size: 14px;
      line-height: 20px;
      vertical-align: middle;
    }

    > a {
      position: relative;
      top: -1px;
    }

    > a.icon {
      top: -1px;
    }
  }

  .unit-body {
    margin-top: 8px;
    width: 100%;
  }
}

.post-comment {

}

.comment-group-reply {
  padding-left: 6%;
}

// Elements
.comment-body {

}

.comment-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
}

// Offsets
* + .comment {
  margin-top: 30px;
}

* + .post-comment {

}

// Adaptive
@include media-breakpoint-up(sm) {
  .comment {
    .comment-body {
      padding-bottom: 30px;
    }
    .comment-meta {
      margin-top: -14px;
      margin-bottom: -13px;
      align-self: flex-end;
      text-align: right;
    }
  }
}

@include media-breakpoint-down(sm) {
  .comment {
    .comment-meta {
      > *:not(:last-child) {
        margin-right: 19px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .comment {
    .comment-meta {
      > *:not(:first-child) {
        margin-left: 29px;
      }
    }
  }

  * + .comment {
    margin-top: 57px;
  }
}

@include media-breakpoint-up(lg) {
  .comment {

  }
}
