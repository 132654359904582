//
// Lists
// --------------------------------------------------
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Description Lists
dl {
  margin: 0;
}

// Default list
.list {
  > li + li {
    margin-top: 5px;
  }
}

.list-xl {
  > li + li {
    margin-top: 34px;
  }

  @include media-breakpoint-up(xl) {
    > li + li {
      margin-top: 44px;
    }
  }
}

// List inline
//

@mixin list-inline($value, $value1 : $value) {
  margin-left: -$value1;
  margin-right: -$value;

  > li {
    display: inline-block;
    padding-left: $value1;
    padding-right: $value;
  }
}

.list-inline {
  @include list-inline(5px);
}

.list-inline-xs {
  @include list-inline(5px);
}

.list-inline-sm {
  @include list-inline(8px);
}

.list-inline-md {
  @include list-inline(15px);
}

.list-inline-lg {
  @include list-inline(20px);
}

// List terms
//

.list-terms {
  dt {
    color: $primary;
  }
  dt + dd {
    margin-top: 15px;
  }

  dd + dt {
    margin-top: 30px;
  }

  @include media-breakpoint-up(md) {
    dd + dt {
      margin-top: 44px;
    }
  }
}

// List index
//

.list-index {
  counter-reset: li;

  > li {
    .list-index-counter {
      &:before {
        content: counter(li, decimal-leading-zero);
        counter-increment: li;
      }
    }
  }
}

// List marked
//

.list-marked {
  text-align: left;
  > li {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    &:before {
      position: absolute;
      top: -4px;
      left: 0;
      content: '\f058';
      font: 400 16px/34px 'FontAwesome';
      color: $primary;
    }

    a {
      display: inline;
      &, &:active, &:focus {
        color: $gray-dark;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  > li.disabled {
    &:before {
      content: '\f057';
      color: $gray-dark;
    }
  }

  > li + li {
    margin-top: 18px;
  }

  @include media-breakpoint-up(md) {
    > li {
      font-size: 22px;

      &:before {
        top: 1px;
        font-size: 20px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    > li {
      padding-left: 44px;
      font-size: 24px;
      line-height: 1.45;

      &:before {
        top: 0;
        font-size: 24px;
      }
    }

    > li + li {
      margin-top: 25px;
    }
  }
}

.page {
  .list-marked-contrast {
    > li {
      color: $white;

      a {
        &, &:active, &:focus {
          color: $white;
        }

        &:hover {
          color: $primary;
        }
      }
    }
    > li:before {
      color: $primary;
    }
  }

  .list-marked-contrast-variant-2 {
    > li {
      color: $white;

      a {
        &, &:active, &:focus {
          color: $white;
        }

        &:hover {
          color: $gray-dark;
        }
      }
    }
    > li:before {
      color: $gray-dark;
    }
  }
}

// List bordered
.list-bordered {
  > li {
    display: inline-block;
  }

  > li:not(:last-child) {
    position: relative;
    padding-right: 30px;

    &:after {
      content: '';
      position: absolute;
      right: 13px;
      top: 58%;
      transform: translateY(-50%);
      width: 1px;
      height: 32px;
      background: $gray-dark;
    }
  }
}

.list-bordered-jungle-green {
  > li:not(:last-child) {
    &:after {
      background: $jungle-green;
    }
  }
}

// List tags
.list-tags {
  > li {
    display: inline-block;
    font-style: italic;

    a {
      &, &:active, &:focus {
        color: $primary;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  > li {
    &:after {
      content: ',';
      display: inline-block;
      color: $gray-lighter;
    }
  }

  > li:last-child {
    &:after {
      display: none;
    }
  }
}

// List numbered
.list-numbered {
  counter-reset: li;

  > li {
    position: relative;
    padding-left: 30px;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: counter(li, decimal) '.';
      counter-increment: li;
    }
  }

  > li + li {
    margin-top: 10px;
  }
}

// List icon pack
.list-icon-pack {
  margin-top: 6px;
  > li {
    margin-top: 25px;

    span + span {
      margin-left: .25em;
    }
  }
}

// List meta
.list-meta {
  $x: 44px;
  $y: 10px;
  margin-top: 30px;
  margin-bottom: -$y;
  margin-left: -$x;
  transform: translateY(-$y);

  > *, > *:first-child {
    display: inline-block;
    vertical-align: middle;
    margin-top: $y;
    margin-left: $x;
  }

  > li {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    > * + * {
      margin-left: .3em;
    }
  }
}

// List links
.list-links {
  > li {
    display: inline-block;
  }

  > li:after {
    content: ';';
  }

  > li:last-child {
    &:after {
      display: none;
    }
  }
}
