//
// Custom Thumbnails
// --------------------------------------------------

.thumbnail {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;

  .caption {
    padding: 0;
  }
}

.img-thumbnail,
.thumbnail {
  box-shadow: none;
}

// Variant 1
.thumbnail-variant-1 {
  text-align: center;
  background-color: transparent;

  * + .caption {
    margin-top: 22px;
    padding: 0 15px;

    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 27px;
    }
  }
}

// Variant 2
.thumbnail-variant-2 {
  width: 100.05%;
  text-align: center;

  img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: none;
    min-width: 100.5%;
  }

  .caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: .33s all ease-in-out;
    pointer-events: none;
    background: rgba($primary, .8);

    &:before {
      display: block;
      font-size: 72px;
      line-height: 72px;
      transition: .33s all ease;
    }

  }

  .desktop & {
    @include media-breakpoint-up(lg) {
      .caption {
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, 30px, 0);
      }

      &:hover {
        .caption {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .thumbnail-variant-1,
  .thumbnail-variant-2 {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Make thumbnails to fit entire container width
.thumbnail-block {
  display: block;

  > img,
  a > img {
    width: 100%;
    height: auto;
  }
}