//
// Pricing
// --------------------------------------------------
.pricing-box {
  padding-bottom: 39px;
  text-align: center;
  background: $white;

  &:hover {
    .pricing-box-price {
      background: $jungle-green;
    }
  }

  .list-marked {
    > li {
      font-size: 20px;

      &:before {
        top: 2px;
      }
    }
  }
}

.pricing-box-header {
  padding: 30px;

  > * {
    color: $primary;
  }
}

.pricing-box-price {
  padding: 21px 15px;
  color: $white;
  background: $primary;
  transition: .33s all ease;
}

.pricing-box-body {
  padding: 37px 20px 35px 40px;
}

.pricing-box-footer {
  padding: 14px;
}

.price {
  font-size: 36px;
  line-height: 1.2;
}

@include media-breakpoint-down(sm) {
  .pricing-box {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-up(sm) {
  .pricing-box-body {
    padding: 37px 35px 35px 58px;
  }

  .price {
    font-size: 48px;
  }
}

@include media-breakpoint-up(md) {
  .pricing-box {
    .list-marked {
      > li {
        padding-left: 44px;
        font-size: 24px;
        line-height: 1.45;

        &:before {
          top: 1px;
          font-size: 24px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .pricing-box-body {
    padding: 37px 25px 35px 38px;
  }
}

@include media-breakpoint-between(lg, lg) {
  .pricing-box {
    .list-marked {
      > li {
        font-size: 20px;

        &:before {
          top: -2px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .pricing-box-body {
    padding: 37px 45px 35px 78px;
  }
}