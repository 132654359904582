//
// Dividers
// --------------------------------------------------
.divider {
  display: inline-block;
}

.divider-sm {
  height: 2px;
  width: 30px;
}

.divider-md {
  height: 2px;
  width: 40px;
}

.divider-center {
  margin-left: auto;
  margin-right: auto;
}

// Styles
.divider-primary {
  background: $primary;
}