//
// Custom utilities
// --------------------------------------------------

// Helpers
// -------------------------
.relative {
  position: relative;
}

.block-top-level {
  position: relative;
  z-index: 3;
}

.height-fill {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > * {
    flex-grow: 1;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-width-1 {
  width: 100%;
  max-width: 625px;
}

.block-centered {
  margin-left: auto;
  margin-right: auto;
}

// Main Layout
// -------------------------
* + h1,
* + .h1 {
  margin-top: 10px;
}

* + h2,
* + .h2 {
  margin-top: 15px;
}

* + h3,
* + .h3 {
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    margin-top: 25px;
  }
}

* + h4,
* + .h4 {
  margin-top: 25px;
}

* + h5,
* + .h5 {
  margin-top: 20px;

  @include media-breakpoint-up(md) {
    margin-top: 30px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 39px;
  }
}

* + h6,
* + .h6 {
  margin-top: 15px;
}

* + p {
  margin-top: 15px;
}

hr + * {
  margin-top: 18px;
  @include media-breakpoint-up(xl) {
    margin-top: 26px;
  }
}

p + p {
  margin-top: 17px;
}

* + .btn {
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 50px;
  }
}

* + .link {
  margin-top: 18px;
}

* + .contact-info {
  margin-top: 25px;
}

* + .list-inline {
  margin-top: 32px;
}

* + .list-terms {
  margin-top: 29px;
}

* + .list-bordered {
  margin-top: 35px;

  @include media-breakpoint-up(md) {
    margin-top: 45px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 64px;
  }
}

// Misc
// -------------------------
.contact-info {
  vertical-align: baseline;

  a {
    display: inline-block;
  }

  dl {
    dt, dd {
      display: inline-block;
    }

    dt {
      @include media-breakpoint-up(md) {
        padding-right: 8px;
      }
    }
    dt:after {
      content: ':';
      display: inline-block;
      text-align: center;
    }
  }

  .dl-inline {
    dt {
      padding-right: 0;
    }
  }
}


.separator {
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  text-align: center;

  &:after {
    content: '\f111';
    font-family: 'FontAwesome';
    font-size: 6px;
    vertical-align: middle;
  }
}