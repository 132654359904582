/*
* Static Layout
*/

.rd-navbar-static {
  display: block;

  .rd-navbar-inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 37px 15px;
  }

  .rd-navbar-panel {
    min-width: 270px;
  }

  // RD Navbar brand
  .rd-navbar-brand {
  }

  // RD Navbar nav wrap
  .rd-navbar-nav-wrap {
    text-align: right;
  }

  .rd-navbar-nav {
    > li {
      display: inline-block;

      & + li {
        margin-left: $navbar-static-nav-indent;
      }
    }
  }

  // RD Navbar clone
  &.rd-navbar--is-clone {
    display: block;
    transform: translateY(-105%);
    transition: .33s all ease;

    &.rd-navbar--is-stuck {
      transform: translateY(0);
    }
  }

  // States
  &.rd-navbar--is-clone,
  &.rd-navbar--is-stuck {
    .rd-navbar-brand {
      .icon {
        position: relative;
        top: 2px;
        @include icon-sizing(37px);
        margin-right: 8px;
      }

      > * {
        font-size: 30px;
      }
    }

    .rd-navbar-panel {
      position: relative;
      top: 2px;
    }

    .rd-navbar-inner {
      padding: 11px 15px;
    }

    .rd-navbar-nav-wrap {
      margin-top: 0;
    }

    .rd-navbar-nav > li > a {
      margin-top: 11px;
      padding-bottom: 11px;
    }
  }
}

