//
// Custom Buttons Styles
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  padding: $padding-base-vertical + 2px $padding-base-horizontal $padding-base-vertical - 2px $padding-base-horizontal;
  font-size: $btn-font-size * 1px;
  font-weight: $btn-font-weight;
  text-transform: uppercase;
  transition: .3s ease-out;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
    box-shadow: none !important;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {

    }
  }

  &:hover,
  &:focus,
  &.focus {

  }

  &:active,
  &.active {
    box-shadow: none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {

  }
}

// Alternate buttons
// --------------------------------------------------
html .page {
  .btn-default {
    @include button-variant-custom($btn-default-color, $btn-default-bg, $btn-default-border, $white, $gray-darker, $gray-darker);
  }

  .btn-primary {
    @include button-variant-custom($btn-primary-color, $btn-primary-bg, $btn-primary-border, $white, $jungle-green, $jungle-green);
    border-bottom: 4px solid $jungle-green;
  }

  .btn-gray-dark {
    @include button-variant-custom($white, $gray-dark, $gray-dark, $white, $gray-darker, $gray-darker);
    border-bottom: 4px solid $gray-darker;
  }

  .btn-primary-outline {
    @include button-variant-custom($primary, transparent, $primary, $white, $primary, $primary);
  }

  .btn-transparent {
    @include button-variant-custom($white, transparent, transparent, $white, rgba($white, .2), transparent);
  }

  // Success appears as green
  .btn-success {
  }

  // Info appears as blue-green
  .btn-info {
  }

  // Warning appears as orange
  .btn-warning {
  }

  // Danger and error appear as red
  .btn-danger {
  }
}

// Button Sizes
// --------------------------------------------------

.btn-xs {
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, 12px, $line-height-base, $border-radius-large);
}

.btn-sm {
  @include button-size($padding-small-vertical, $padding-small-horizontal, 14px, $line-height-base, $border-radius-large);
}

.btn-lg {
  @include button-size($padding-large-vertical, $padding-large-horizontal, 16px, $line-height-base, $border-radius-large);
}

// Button Shapes
// --------------------------------------------------

.btn-rect {
  border-radius: 0;
}

// Button Icon styles
// --------------------------------------------------

.btn {

  &.btn-icon {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;

    .icon {
      position: relative;
      top: -2px;
      display: inline;
      width: auto;
      height: auto;
      line-height: 0;
      vertical-align: middle;
      transition: 0s;
    }

    &-left {
      .icon {
        margin-right: 10px;
      }
    }

    &-right {
      flex-direction: row-reverse;
      .icon {
        margin-left: 10px;
      }
    }

  }
}