//
// Search Results
// --------------------------------------------------
// Base styles
// --------------------------------------------------

.rd-search {
  position: relative;

  .form-wrap {
    display: block;
    margin: 0;
    font-size: 0;
  }

  .rd-search-submit {
    top: 50%;
    background-color: transparent;
    border: none;
    transform: translateY(-50%);
  }
}

.rd-search-submit {
  @include reset-button();
  @include make-toggle(
      $rd-navbar-search-toggle-preset,
      $rd-navbar-min-line-height,
      22px,
      $gray-darker
  );
  position: absolute;
  right: 0;
  transition: color .33s;

  &:hover {
    color: $primary;
  }
}

.rd-search-inline {
  button {
   flex-shrink: 0;
  }

  > * + * {
    margin-top: 20px;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;

    .form-wrap {
      flex-grow: 1;
    }

    > * + * {
      margin-top: 0;
      margin-left: 17px;
    }

    button {
      padding-left: 56px;
      padding-right: 56px;
    }
  }
}

// Live Search Results
.rd-search-results-live {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 998;
  margin: -3px 0 0;
  border: $form-input-border;
  border-top: 0;
  font-size: $font-size-body;
  line-height: 34px;
  text-align: left;
  color: $body-color;
  background: $form-input-background;
  max-height: 320px;
  overflow-x: hidden;
  overflow-y: auto;

  > * {
    padding: 16px;
  }

  .search-quick-result {
    font-size: 16px;
    line-height: 30px;
    color: $gray-dark;
  }

  .search-list {
    background: transparent;
    margin-top: 7px;
    font-size: 16px;
    line-height: 30px;

    li + li {
      margin-top: 18px;
    }

    .search-list li + li:last-child {
      margin-top: 8px;
      border-top: 1px solid $gray-light;
      padding-top: 7px;
    }

    .search-error {
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .search-link {
    &, &:active, &:focus {
      color: $primary;
    }

    &:hover {
      color: $gray-dark;
    }
  }

  p {
    margin-top: 0;
    font-size: 14px;
    line-height: 1.6;
  }

  .match {
    display: none;
  }

  .search-title {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    color: $primary;
  }

  .search-submit {
    display: block;
    padding: 8px 6px 4px;
    font-size: $font-size-body;
    font-weight: $btn-font-weight;
    text-align: center;
    color: $white;
    text-transform: uppercase;
    background: $primary;
    border-bottom: 4px solid $jungle-green;

    &:hover {
      color: $white;
      background: $jungle-green;
    }
  }

  .not-empty ~ & {
    visibility: visible;
    opacity: 1;
  }

  @include media-breakpoint-up(xl) {
    .search-link {
      p {
        display: block;
      }
    }
  }
}

.search-error {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rd-navbar-fixed-linked {
  .rd-search-results-live {
    display: none;
  }
}

.rd-search-results-live-dark {
  color: $body-color;
  background: $white;
  border-color: $gray-lighter;

  .search-error,
  .search-list {
    background: transparent;
  }

  .search-quick-result {
    color: $body-color;
    background: $white;
  }

  .search-link {
    &, &:active, &:focus {
      color: $body-color;
    }

    &:hover {
      color: $primary;
    }
  }

  .search-submit {
    color: $primary;

    &:hover {
      color: $white;
      background: $primary;
    }
  }
}

.search-list {
  text-align: left;
  padding-left: 0;
  font-size: 14px;
  list-style-type: none;
}

.search-list-item {
  * + p {
    margin-top: 3px;
  }
}

.search-list-item + .search-list-item {
  margin-top: 30px;

  @include media-breakpoint-up(lg) {
    margin-top: 44px;
  }
}

.match {
  font-size: 13px;
  line-height: 1.5;
  color: $primary;
}
