//
// Box custom
// --------------------------------------------------
.box-custom {
  padding: 0 10px;
  text-align: center;

  .icon {
    transition: .33s all ease;
  }

  &:hover {
    .icon-white {
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  .box-custom {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

.icon-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .box-left {
    margin-top: 6px;
    flex-shrink: 0;
  }

  .box-body {
    margin-top: 23px;
  }

  .icon {
    transition: .33s all ease;
  }

  &:hover {
    .icon-primary {
      color: $gray-dark;
    }

    #{headings()} {
      a {
        &, &:active, &:focus {
          color: $gray-dark;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

// Icon box variant 3
.icon-box-variant-3 {
  .icon {
    text-align: left;
    transition: .33s all ease;
  }

  &:hover {
    .icon {
      color: $white;
    }

    #{headings()} {
      a {
        &, &:active, &:focus {
          color: $white;
        }

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

* +  .icon-box-variant-3 {
  margin-top: 42px;
}

@include media-breakpoint-between(lg, lg) {
  .icon-box {
    .box-left {
      margin-top: 5px;
    }

    .icon-sm,
    .icon-md {
      @include icon-sizing(38px);
    }
  }
}

@include media-breakpoint-up(md) {
  .box-custom {
    padding: 0 25px;
  }

  .icon-box-variant-2 {
    padding-right: 35px;
    .unit-left {
      position: relative;
      top: 10px;
    }
  }

  .icon-box-variant-3 {
    .unit-left {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .icon-box {
    flex-direction: row;
    text-align: left;

    .box-body {
      margin-top: 0;
      padding-left: 23px;
    }
  }
  .icon-box.icon-box-right {
    text-align: right;
    flex-direction: row-reverse;

    .box-body {
      padding-right: 23px;
      padding-left: 0;
    }
  }
}