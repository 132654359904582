//
// Links mixins
// --------------------------------------------------

@mixin link($default-clr, $hover-color) {
  &, &:active, &:focus {
    color: $default-clr;
  }

  &:hover {
    color: $hover-color;
  }
}