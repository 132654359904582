//
// Quotes custom
// --------------------------------------------------

// Blockquotes
blockquote {
  font: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  q {
    &:before,
    &:after {
      content: none;
    }
  }
  cite {
    font-style: normal;
  }
}

.quote {
  &-meta {
  }

  cite {

  }
}

@include media-breakpoint-up(md) {
  .quote {

  }
}

@include media-breakpoint-up(lg) {
  .quote {

  }
}