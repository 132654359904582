//
// Figure
// --------------------------------------------------
figure {
  img {
    width: 100%;
    height: auto;
    max-width: none;
  }
}

.figure {
  .caption {
    padding: 15px;
  }
}

.figure-variant-1 {
  #{headings()} {
    color: $white;
  }

  .caption {
    padding: 0;
  }

  * + .subheader {
    margin-top: 0;
  }

  * + .caption {
    margin-top: 15px;
  }
}

@include media-breakpoint-up(md) {
  .figure-variant-1 {
    *+ .caption {
      margin-top: 25px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .figure {
    .caption {
    }
  }
}

@include media-breakpoint-up(xl) {
  .figure-variant-1 {
    *+ .caption {
      margin-top: 30px;
    }
  }
}